<template>
  <v-card v-bind="$attrs">
    <div v-if="$slots.header" class="header">
      <v-card-title class="primary--text pa-4">
        <slot name="header" />
      </v-card-title>
      <v-progress-linear v-if="loading" indeterminate color="primary" />
      <v-divider v-if="dividers" />
    </div>
    <v-card-text
      v-if="$slots.default"
      :class="contentClass || (dividers ? 'pa-4' : 'py-0 px-4')"
    >
      <slot />
    </v-card-text>
    <slot name="no-offset" />
    <div v-if="$slots.footer" class="footer">
      <v-divider v-if="dividers" />
      <v-card-actions class="pa-4">
        <slot name="footer" />
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'PopupLayoutDefault',
  props: {
    dividers: {
      type: [Boolean],
      default: true
    },
    contentClass: {
      type: [String, Object],
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
::v-deep
  .form-subheader:first-child
    margin-top: -16px
</style>
